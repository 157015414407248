import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";



const ReleaseList = () => {


    const [release, setRelease] = useState([]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Calendar View" });
        fetch("/release.json").then(res => res.json()).then(json => setRelease(json))
    }, []);

    return <Grid container spacing={5}>
        {release.map((r,i) =>
            <Grid key={i} item xs={12}>
              <Card>
                <CardContent>
                  <div style={{float:"right"}}>{r.d}</div>
                  <Typography variant={"h4"}>{r.v}</Typography>
                  <div style={{whiteSpace: "pre-wrap"}}>{r.t}</div>
                </CardContent>
              </Card>
            </Grid>
        )}
    </Grid>
}

export default ReleaseList;