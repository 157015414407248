import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {dateToHumanDateStr} from "../date.js";
import {Card, CardContent, Chip} from "@mui/material";
import Grid from "@mui/material/Grid";
import {sliceIn2} from "../transform.js";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const api_host = process.env.REACT_APP_API_HOST;

const Artist = () => {

    const [events, setEvents] = useState([]);
    const [artist, setArtist] = useState({});
    const params = useParams();

    const getEvents = () => {
        fetch(`/events.json`)
            .then(res => res.json())
            .then(events => events.filter(e => e.bandKey == params.bandKey))
            .then(res => setEvents(res))
    }

     const getArtist = (artistKey) => fetch(api_host + "/?action=ArtistGet&artistKey=" + artistKey)
        .then(res => res.json())
        .then(artist => {
           setArtist(artist)
        });

    useEffect(() => {
        //ensure a refresh every hour
        setInterval(getEvents, 3600 * 1000);
        getEvents();
        getArtist(params.bandKey)
    }, [])

    const backButton = (document.referrer.indexOf("localhost") > 0 || document.referrer.indexOf("austin2step.com") > 0) ?
        <IconButton size={"small"} color={"primary"} onClick={() => window.history.back()}><ArrowBackIcon />Back</IconButton> :
        <IconButton size={"small"} color={"primary"} href={"/"}><ArrowBackIcon />Full Calendar</IconButton>

    return <Grid container>
            <Grid item xs={12}>
                {backButton}
                <Card>
                    <CardContent>
                        <span style={{fontSize: "150%"}}>{artist.name}</span>{artist.tag && <Chip size={"small"} label={artist.tag}/>}
                        {artist.instagram && <div><IconButton size={"small"}
                                                              href={"https://instagram.com/" + artist.instagram + "/"}><InstagramIcon/>&nbsp;
                            <Link size={"small"}>{artist.instagram}</Link></IconButton></div>}
                        <Divider/>
                         <h6>Upcoming Shows</h6>
                        <Grid container>
                            {sliceIn2(events).map(halfEvents =>
                                <Grid item xs={12} md={6} style={{marginBottom: "10px"}}>
                                    {halfEvents.map(e =>
                                        <div style={{paddingBottom: "10px"}}>
                                            <div>{dateToHumanDateStr(new Date(e.date + " 12:00:00"))} @ {e.time}</div>
                                            <div>{e.venue}</div>
                                        </div>)}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
}

export default Artist;