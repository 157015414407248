import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React from "react";
import Grid from "@mui/material/Grid";
import { RSVPButton } from "./RSVP.jsx";
import {Chip} from "@mui/material";

const DanceDayDetailed = (props) => {

    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(props.danceDay.date + "T12:00:00");
    const dstr = dayNames[date.getDay()] + " " + monNames[date.getMonth()] + " " + date.getDate();
    const rsvpDate = props.danceDay.date;
    const data = props.danceDay.data;

    const venueDay = (isLoggedIn, venue) => <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                                <div>
                                    <span style={{fontSize: "150%"}}>{venue.venue}</span>
                                    <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                                      venueKey={venue.venueKey}/>
                                </div>
                                <div>
                                    {venue.data.map((slots, i) => <div
                                        key={i}><strong>{slots.time}</strong> {slots.danceable ?
                                        <strong>{slots.band}</strong> : slots.band}{slots.tag && <Chip size={"small"} label={slots.tag} />}</div>)}
                                </div>
                            </div>

    return (
        <Card variant={"outlined"}>
                <CardContent>

                    <Typography variant="h4">{dstr}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        {data.slice(0, Math.ceil(data.length / 2)).map((venue) =>
                            venueDay(props.isLoggedIn, venue)
                        )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        {data.slice(Math.ceil(data.length / 2), data.length).map((venue, i) =>
                            <div key={i}>
                                {venueDay(props.isLoggedIn, venue)}
                            </div>
                        )}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
    );
}

export default DanceDayDetailed;