import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, {useContext} from "react";
import Grid from "@mui/material/Grid";
import { RSVPButton } from "./RSVP.jsx";
import {Chip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import {dateToHumanDateStr} from "../date.js";
import {MonetizationOn} from "@mui/icons-material";
import ReactGA from "react-ga4";
import UserContext from "./UserContext.jsx";

const DanceDay = (props) => {

    const date = new Date(props.danceDay.date + "T12:00:00");
    const dstr = dateToHumanDateStr(date);
    const rsvpDate = props.danceDay.date;
    const data = props.danceDay.data;

    const user = useContext(UserContext);

    const venueDay = (isLoggedIn, venue) => <div style={{paddingBottom: "20px"}} key={venue.venueKey}>
                                <div>
                                    <span style={{fontSize: "125%"}}>{venue.venue}</span>
                                    <RSVPButton style={{marginTop: "3px"}} date={rsvpDate}
                                                      venueKey={venue.venueKey}/>
                                </div>
                                <div>
                                    {venue.data.map((slots, i) => <div
                                        key={i}><strong>{slots.time}</strong> {slots.danceable ?
                                        <strong><Link href={"/artist/" + slots.bandKey}>{slots.band}</Link></strong> : slots.band} {slots.venmo && <Chip onClick={e => user.setTipBoxData({band: slots.band, url: slots.venmo})} size={"small"} color={"info"} label={"tip"} component={"a"} clickable/>}</div>)}
                                </div>
                            </div>

    return (
        <Card variant={"outlined"}>
                <CardContent>
                    {/*<IconButton size={"small"} style={{float: "right"}} onClick={() => window.location = "/calendar/" + props.danceDay.date}>Details</IconButton>*/}
                    <Typography variant="h4">{dstr}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        {data.slice(0, Math.ceil(data.length / 2)).map((venue) =>
                            venueDay(props.isLoggedIn, venue)
                        )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        {data.slice(Math.ceil(data.length / 2), data.length).map((venue, i) =>
                            <div key={i}>
                                {venueDay(props.isLoggedIn, venue)}
                            </div>
                        )}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
    );
}

export default DanceDay;