import {CardContent, CardMedia, CardHeader, Link} from "@mui/material";
import {FormControl, FormLabel, Radio, RadioGroup} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Card from "@mui/material/Card";
import React, {useEffect, useState} from "react";
import UserContext from "./UserContext.jsx";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import {Helmet} from "react-helmet";

const FieldTrip = (props) => {

    const api_host = process.env.REACT_APP_API_HOST;

    let user = React.useContext(UserContext);
    let [status, setStatus] = useState("loading");
    let [counts, setCounts] = useState({lead: "-", follow: "-", none: "-", switch: "-"})
    let [alertOpen, setAlertOpen] = useState(false);
    let alertMessage = "RSVP Updated!";

    const getFieldTripStatus = () => {
        if (user.jti === undefined) {
            return;
        }
        fetch(api_host + "/?action=FieldtripGetUserStatus&jti=" + user.jti)
            .then(res => res.json())
            .then(res => setStatus(res.role))
    }

    const getCounts = () =>  {
        fetch(api_host + "/?action=FieldtripCounts")
            .then(res => res.json())
            .then(res => setCounts(res))
    }

    const handleChange = (e) => {

        e.stopPropagation();
        fetch(api_host + "/?action=FieldtripSetUserStatus&jti=" + user.jti + "&role=" + e.target.value)
            .then(res => res.json())
            .then(res => setStatus(res.role))
            .then(res => getCounts())
            .then(setAlertOpen(true))
            .then(setTimeout(() => setAlertOpen(false), 1200))

    }

    useEffect(() => {
        getFieldTripStatus();
        getCounts();
    }, []);

    let form;
        form = <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">RSVP HERE</FormLabel>
                  <RadioGroup name="field-trip-group" value={status} onChange={handleChange}>
                    <FormControlLabel value="none" control={<Radio style={{padding: "3px"}}/>} label="Not Going" disabled={!user.loggedIn}/>
                    <FormControlLabel value="lead" control={<Radio style={{padding: "3px"}}/>} label="Going (lead)" disabled={!user.loggedIn}/>
                    <FormControlLabel value="follow" control={<Radio style={{padding: "3px"}}/>} label="Going (follow)" disabled={!user.loggedIn}/>
                    <FormControlLabel value="switch" control={<Radio style={{padding: "3px"}}/>} label="Going (Dance both roles)" disabled={!user.loggedIn}/>
                  </RadioGroup>
                </FormControl>


    return <Grid container>
            <Dialog open={alertOpen}><Alert>RSVP Saved!</Alert></Dialog>
             <Grid item xs={12}>
                 <Helmet>
                    <meta charSet="utf-8"/>
                    <meta property="og:description" content="The next field trip for Austin 2 Steppers!"/>
                    <meta property="twitter:title" content={"The next field trip for Austin 2 Steppers!"}/>
                    <meta property="twitter:description" content="The next field trip for Austin 2 Steppers!"/>
                    <title>2 Step Field Trip</title>
                    <meta property="description" content="The next field trip for Austin 2 Steppers!" />
                    <link rel="canonical" href="https://austin2step.com/fieldtrip"/>
                </Helmet>
                 <Card>
                     <CardContent>
                         <h6>No field trip announced for now. Check back soon!</h6>
                     </CardContent>
                 </Card>
               {/*<Card>*/}
               {/*    /!*<CardContent>*!/*/}
               {/*    /!*    No new field trip picked out yet, but we will do it again :)*!/*/}
               {/*    /!*</CardContent>*!/*/}
               {/*    <CardHeader title={"Field Trip!"} subheader={"This Friday"}/>*/}
               {/*     <CardMedia component={"img"}*/}
               {/*         height={300}*/}
               {/*         image={"https://allhatnocadillac.com/wp-content/uploads/2023/11/All-Hat.jpeg"}*/}
               {/*     />*/}
               {/*  <CardContent>*/}
               {/*      <Grid container>*/}
               {/*        <Grid item xs={12} sm={6}>*/}
               {/*          <p>90's Country night with <Link href={"https://allhatnocadillac.com/"}>All Hat, No Cadillac</Link>!</p>*/}
               {/*          <div>Coupland Dance Hall</div>*/}
               {/*          <div>Friday August 9th</div>*/}

               {/*        </Grid>*/}
               {/*        <Grid item xs={12} sm={6}>*/}
               {/*            {!user.loggedIn && <h6>Login in the top right corner to add your RSVP! More info below.</h6>}*/}
               {/*            {form}*/}
               {/*        </Grid>*/}
               {/*          <Grid item xs={12}>*/}
               {/*          <hr/>*/}
               {/*        </Grid>*/}
               {/*        <Grid item xs={12} sm={4}>*/}
               {/*          <div>Leads: {counts.lead}</div>*/}
               {/*        </Grid>*/}
               {/*        <Grid item xs={12} sm={4}>*/}
               {/*          <div>Follows: {counts.follow}</div>*/}
               {/*        </Grid>*/}
               {/*        <Grid item xs={12} sm={4}>*/}
               {/*          <div>Both roles: {counts.switch}</div>*/}
               {/*        </Grid>*/}

               {/*      </Grid>*/}
               {/*    </CardContent>*/}
               {/*  </Card>*/}
                 <Card style={{marginTop: "20px"}}>
                     <CardContent>
                       <h6>Why was this feature built?</h6>
                       <p>Do you ever drive way outside the city and when you get there, its all follows? I have, well except it was all leads haha.</p>
                       <p>So I am trying out a new field trip feature, where dancers can RSVP as lead or follows, then display the total here. Hopefully this will take some of the guess work out of venturing out. If people like it, we can do more in the future.</p>
                       <h6>How does it work?</h6>
                       <p>In order to track RSVPs this feature does require a login. Use the menu on the top right. It will prompt you to use your Google login. Google will ask if this ok.</p>
                       <h6>What does this do with my Google info?</h6>
                       <p>Using this feature does expose your Google email, name and profile picture if you have one to this app. These are stored only as long as you are logged in. Upon log out this information is discarded.</p>
                       <p>Google also gives this app a unique user identifier. This identifier is re-supplied to this app everytime you log in so I can tie your RSVP to your ID to avoid duplicates/lost data, etc. This is not creating an account on this website.</p>
                     </CardContent>
                 </Card>
               </Grid>
             </Grid>
}

export default FieldTrip;