import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

const Contact = () => {
    return <Grid container>
        <Grid item>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Contact Us</Typography>
                    <div>For now, contact through <a href={"https://instagram.com/austin2step"}>Instagram</a></div>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default Contact