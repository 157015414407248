import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet";

const TwoStepLessons = () => {

    const title = "All two step dance lessons in Austin.";

    return <Grid container>
        <Grid item>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:description" content="Where to find 2 step lessons tonight and every day!"/>
                <meta property="twitter:title" content={title}/>
                <meta property="twitter:description" content="Where to find 2 step lessons tonight and every day!"/>
                <title>{title}</title>
                <meta property="description" content="All of the two step lessons tonight in Austin. No partner needed. Dancing to live music after." />
                <link rel="canonical" href="https://austin2step.com/twostep-lessons"/>
            </Helmet>
            <Typography variant={"h4"}>Where to find two-step dance lessons tonight.</Typography>
            <p>These are the most popular dance lessons for two-step in Austin. All of these lessons are "drop-in", which means you just show up when the class starts, no partner needed. This is the easiest and quickest way to earn some two step experience and then dance afterwards to live music. The true Austin experience.</p>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Monday</Typography>
                    <Typography variant={"h6"}>Sam's Town Point</Typography>
                    <p>8:30p - Advanced Two Step<br />
                    Hosted by Double or Nothing Two Step</p>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Tuesday</Typography>
                    <Typography variant={"h6"}>Little Longhorn</Typography>
                    <p>7p - Beginner Two Step<br />Hosted by Hill Country Two Step</p>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Wednesday</Typography>
                    <Typography variant={"h6"}>Broken Spoke</Typography>
                    <p>8p - Beginner Two Step<br/>Hosted by Terri White</p>
                    <Typography variant={"h6"}>Donn's Depot</Typography>
                    <p>7:30p - Beginner Two Step<br/>Hosted by Double or Nothing Two Step</p>
                    <Typography variant={"h6"}>Little Longhorn</Typography>
                    <p>7p - Intermediate Two Step<br/>Hosted by Hill Country Two Step</p>
                    <Typography variant={"h6"}>Sagebrush</Typography>
                    <p>7p - Intermediate Two Step<br/>Hosted by Hill Country Two Step</p>
                </CardContent>
            </Card>
              <Card>
                <CardContent>
                    <Typography variant={"h4"}>Thursday</Typography>
                    <Typography variant={"h6"}>Broken Spoke</Typography>
                    <p>8p - Beginner Two Step<br/>Hosted by Terri White</p>
                    <Typography variant={"h6"}>Sagebrush</Typography>
                    <p>7p - Beginner Two Step<br/>Hosted by Hill Country Two Step</p>
                    <Typography variant={"h6"}>Sam's Town Point</Typography>
                    <p>6p - Beginner Two Step <br/>
                        7p - Intermediate Two Step
                    <br/>Hosted by Double or Nothing Two Step</p>
                    <Typography variant={"h6"}>White Horse</Typography>
                    <p>7p - Beginner Two Step<br/>Hosted by Dancin Austin</p>
                </CardContent>
            </Card>
             <Card>
                <CardContent>
                    <Typography variant={"h4"}>Friday</Typography>
                    <Typography variant={"h6"}>Broken Spoke</Typography>
                    <p>8p - Beginner Two Step<br/>Hosted by Terri White</p>
                    <Typography variant={"h6"}>White Horse</Typography>
                    <p>7p - Beginner Two Step<br />Hosted by Dancin Austin</p>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography variant={"h4"}>Saturday</Typography>
                    <Typography variant={"h6"}>Broken Spoke</Typography>
                    <p>8p - Beginner Two Step<br />Hosted by Terri White</p>
                    <Typography variant={"h6"}>White Horse</Typography>
                    <p>7p - Beginner Two Step<br/>Hosted by Dancin Austin</p>
                </CardContent>
            </Card>

        </Grid>
    </Grid>
}

export default TwoStepLessons