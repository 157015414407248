import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import React, {useContext, useState} from "react";
import userContext from "./UserContext.jsx";
import IconButton from "@mui/material/IconButton";

const RSVPButton = (props) => {
    const [loading, setLoading] = useState(false);
    const user = useContext(userContext);
    const {date, venueKey} = props;


    const requireLogin = () => {user.setSnackBar({open: true, message: "You must log in to star events"})}
    if (!user.loggedIn) {
        return <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "-1px"}}><StarBorderIcon onClick={requireLogin}/></IconButton>
    }

   if (loading) {
       return <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "-1px"}}><AutorenewIcon  sx={{
           animation: "spin 2s linear infinite",
           "@keyframes spin": {
               "0%": {
                   transform: "rotate(0deg)",
               },
               "100%": {
                   transform: "rotate(360deg)",
               },
           },
       }} /></IconButton>
   }

    return RSVP.isRSVP(user.rsvpMap, date, venueKey) ?
        <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "-1px"}} onClick={() => RSVP.toggle(user, date, venueKey, setLoading)}><StarIcon style={{color: "yellow"}} /></IconButton> :
        <IconButton size={"small"} sx={{paddingTop: 0, marginTop: "-1px"}} onClick={() => RSVP.toggle(user, date, venueKey, setLoading)}><StarBorderIcon /></IconButton>
}

const api_host = process.env.REACT_APP_API_HOST;

const RSVP = {

    isRSVP: (map, date, venueKey) => {
        const property = date + "-" + venueKey;
        return map.hasOwnProperty(property);
    },
    toggle: async (user, date, venueKey, setLoading) => {
        const property = date + "-" + venueKey;
        if (typeof user.jti === undefined) {
            return;
        }

        setLoading(true);

        if (user.rsvpMap.hasOwnProperty(property)) {
            await fetch(api_host + "?action=RSVPUnset&jti=" + user.jti + "&date=" + date + "&venueKey=" + venueKey)
                .then(res => res.json())
            user.setSnackBar({open:true, message:"Star removed successfully"})
        } else {
            await fetch(api_host + "?action=RSVPSet&jti=" + user.jti + "&date=" + date + "&venueKey=" + venueKey)
                .then(res => res.json())
            user.setSnackBar({open:true, message:"Star added successfully"})
        }
        user.setRSVPMap(await RSVP.load(user.jti));
        setLoading(false);
    },

    load: async (jti) => {
        let map = {};
        if (jti === undefined) {
            return map;
        }

        const reducer = (acc, item) => {
            acc[item] = true;
            return acc;
        }

        map = await fetch(api_host + "?action=RSVPList&jti=" + jti)
            .then(res => res.json())
            .then(arr => map = arr.reduce(reducer, {}))
        window.localStorage.setItem("RSVPMap", JSON.stringify(map));
        return map;
    }
}

export { RSVPButton, RSVP };