import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Typography,Link} from "@mui/material";
import Grid from "@mui/material/Grid";
import {Helmet} from "react-helmet";

const About = () => {
    return <Grid container>
        <Grid item>
            <Helmet>
                <meta charSet="utf-8"/>
                <meta property="og:description" content="learn more about this site - dancers for dancers"/>
                <meta property="twitter:title" content={"About & Mission of Austin 2 step"}/>
                <meta property="twitter:description" content="learn more about this site - dancers for dancers"/>
                <title>About Austin 2 step</title>
                <meta property="description" content="learn more about this site - dancers for dancers" />
                <link rel="canonical" href="https://austin2step.com/about"/>
            </Helmet>
            <Card>
                <CardContent>
                    <Typography variant={"h6"}>About</Typography>
                    <p>Austin 2 Step is a tool for discovering all things two step in Austin, the honky tonk capitol of the world, including where dancer's favorite bands are playing, where lessons are happening and more.</p>

                    <Typography variant={"h6"}>Mission</Typography>
                    <p>This site was created to enable the growing Austin 2 community discover more opportunities to dance. Everyone knows where to reliably go on Tuesday and Saturday but so much goes undiscovered. As development progresses, this site should make it easier to find new dance opportunities. The current popular days are still cherished, the aim is not to diminish these nights but to grow others.</p>
                    <p>This target for this site is first and foremost for the dancers, however, us two steppers wouldn't have any of this amazing honky tonk life if it wasn't for the venues, musicians and dance instructors. With that in mind, I wish to provide a platform that is unbiased towards any of them. Ideally, the more opportunities that present themselves for dancers, the more they discover new music, venues and dancing, the more the community will grow and we can pack even more places.</p>

                    <Typography variant={"h6"}>Me</Typography>
                    <p>Hi, I'm Tim. I'm a dance addict haha. I have danced most nights since 2012. And I'm not gonna stop any time soon.</p>
                    <p>I also am a <Link href={"https://neon2step.com"}> dance instructor</Link>. In an effort to keep things community based on this site, I will always show the other's instructors dance lessons and plan to add more features to make them more visible.</p>
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default About